import React from "react";
import { Container, Paper, Divider, Grid, makeStyles, Link } from "@material-ui/core";
import { Breadcrumbs } from "gatsby-parallelpublicworks/src/components";
import { Helmet } from "react-helmet";
import getVariantSlug from "../utils/getVariantSlug";

const renderFields = [
  {
    field: "field_gene",
    headerName: "Gene",
    width: 150,
    editable: true,
  },
  {
    field: "field_c",
    headerName: "Coding Change (c.)",
    width: 200,
    editable: true,
  },
  {
    field: "field_p",
    headerName: "Protein Change (p.)",
    width: 150,
    editable: true,
  },
  {
    field: "field_clinvar",
    headerName: "ClinVar Name",
    width: 225,
    editable: true,
  },
  {
    field: "field_clinvar_num",
    headerName: "ClinVar Number",
    width: 180,
    editable: true,
  },
  {
    field: "field_forum_url",
    headerName: "Variant Forum",
    width: 180,
    editable: true,
    renderCell: (params) => {
      if (!params?.value) return <></>;

      return (
        <p>
          <Link href={params.value.uri}>{params.value.uri}</Link>
        </p>
      );
    },
  },
  {
    field: "field_public_notes",
    headerName: "Notes",
    width: 225,
    editable: true,
    renderCell: ({ value: note }) => {
      if (!note?.value) return <></>;
      return <div dangerouslySetInnerHTML={{ __html: note.value }} />;
    },
  },
  {
    field: "field_other_names",
    headerName: "Other Names",
    width: 225,
    editable: true,
    renderCell: (params) => {
      return <p>{params.value?.join(", ") || ""}</p>;
    },
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(3),
  },
}));

function VariantLayout({ pageContext, ...props }) {
  const classes = useStyles();
  const { attributes } = pageContext;
  const title = getVariantSlug(attributes, true);
  return (
    <Container maxWidth="md" className={classes.root}>
      <Helmet title={title} defer={false} />
      <Paper elevation={3} className={classes.paper}>
        <Breadcrumbs
          ancestors={[{ id: "variants", title: "Variants", slug: "/variants" }]}
          current={title}
        />
        <h2>{title}</h2>
        <Divider />
        <Grid container spacing={1}>
          {renderFields.map((field) => {
            let value = attributes[field.field];
            if (field.field === "field_gene") {
              value = value.attributes["title"];
            }
            let valid =
              (Array.isArray(value) && value.length > 0) ||
              (!Array.isArray(value) && value);
            if (valid) {
              return (
                <React.Fragment key={field.field}>
                  <Grid item xs={3}>
                    <h3>{field.headerName}</h3>
                  </Grid>
                  <Grid item xs={9}>
                    {field.renderCell ? field.renderCell({ value }) : <p>{value}</p>}
                  </Grid>
                </React.Fragment>
              );
            } else {
              return <React.Fragment key={field.field}></React.Fragment>;
            }
          })}
        </Grid>
      </Paper>
    </Container>
  );
}

export default VariantLayout;
